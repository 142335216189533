import AuthStore from './authStore'
import AreaStore from './areaStore'
import WorkStationStore from './workStationStore'
import MainHub from './hubs/mainHub'
import ApplicationStore from './applicationStore'
import SettingsStore from './settingsStore'
import WorkStationHub from './hubs/workStationHub'
import ToastMiddleware from './middlewares/toastMiddleware'

export class RootStore {
  constructor() {
    this.authStore = new AuthStore(this)
    this.applicationStore = new ApplicationStore(this)
    this.settingsStore = new SettingsStore(this)
    this.areaStore = new AreaStore(this)
    this.workStationStore = new WorkStationStore(this)
    this.toastMiddleware = new ToastMiddleware()

    this.mainHub = new MainHub(this)
    this.workStationHub = new WorkStationHub(this)
  }
}

export default new RootStore()
